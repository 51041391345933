import ErrorBoundary from '../../helpers/components/ErrorBoundary';
import { DFPContainer } from '../Toolkit/DFPContainer/DFPContainer';

import React, { ComponentType, Fragment, useMemo, useRef } from 'react';

import { AdSlot } from '@dsch/react-dfp';
import { SemanticButtonTypes } from '../../components/Toolkit/NewButton/NewButton';
import FilterButtons from './FilterButtons';
import * as S from './SearchPage.styled';
import SideColumn from './SideColumn';
import { MainFlexWrapper, SearchPageListViewResultsHeader } from './styles';
import ZeroResults from './ZeroResults';
import SortFilter from '../Filters/Sort/Sort';
import { QuickLinksRadiusFilter } from '../MultiInputTextSelect/RadiusFilter';
import { DFPScreenSizes, DFPUnitSizes } from '../Toolkit/DFPContainer/DFPSizes';
import { Listing, SECTIONS, SearchPropertyCard } from '../../types';
import {
  FilterRequestAPI,
  FilterSearchQuery,
} from '../../services/Filters/FilterTypes';
import dynamic from 'next/dynamic';
import { renderCard } from './SearchPage';
import Link from 'next/link';
import { useListingParams } from './hooks/SRPCardListingParams.hook';
import { createCard } from '../Toolkit/Card/CardFactory';
import { preparePropertyUnitData } from './services/propertyUnitService';
import { SRPResultCards } from './SRPResultCards';
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Icon,
  Link as StyledLink,
  Text,
  TextFontWeight,
  TextSize,
  Notification,
  NotificationFontSizes,
  NotificationStatus,
  NotificationVariants,
  Space,
} from '@dist-property-frontend/toolkit';
import SearchPageListViewStickyButton from './SearchPageListViewStickyButton';
import { UrlServiceFilterObject } from '../../services/Url/UrlService';

const AdvantagePanel: ComponentType<{ isMobile: boolean }> = dynamic(
  () =>
    import(
      /* webpackChunkName: "advantage-panel" */ './SearchPageAdvantagePanel'
    ).then((module) => module.default),
  {
    ssr: false,
  },
);

const {
  BILLBOARD,
  LEADERBOARD,
  LEADERBOARD_LARGE,
  LEADERBOARD_XL,
  MOBILE_LEADERBOARD,
  MPU_300,
} = DFPUnitSizes;

const { VIEW_DESKTOP, VIEW_MOBILE, VIEW_TABLET_PLUS } = DFPScreenSizes;

/**
 * :(
 * At index 3 (position 4) we add a property card + unit.
 * This unit goes to position 5 without affecting the index.
 * Then at index 7, we add another property card + Unit.
 * As a result the second Unit added ends up at position 10.
 */
const getUnitIdBasedOnPositionInResults = (index: 3 | 7 | 11) => {
  const adIdMap = {
    3: 5,
    7: 10,
    11: 15,
  };

  return adIdMap[index];
};

export const getSizesByIndex = (index: 3 | 7 | 11) => {
  const mappings: { [key: number]: any } = {
    3: {
      sizeMapping: [
        {
          viewport: VIEW_DESKTOP,
          sizes: [[680, 275], MPU_300, 'fluid'],
        },
        {
          viewport: VIEW_MOBILE,
          sizes: [MPU_300, 'fluid'],
        },
      ],
      sizes: [[680, 275], MPU_300, 'fluid'],
    },
    7: {
      sizeMapping: [
        {
          viewport: VIEW_DESKTOP,
          sizes: [[680, 275], MPU_300, 'fluid'],
        },
        {
          viewport: VIEW_MOBILE,
          sizes: [MPU_300, 'fluid'],
        },
      ],
      sizes: [[680, 275], MPU_300, 'fluid'],
    },
    11: {
      sizeMapping: [
        {
          viewport: [801, 0],
          sizes: ['fluid'],
        },
        {
          viewport: VIEW_MOBILE,
          sizes: [MOBILE_LEADERBOARD, 'fluid'],
        },
      ],
      sizes: [MOBILE_LEADERBOARD, 'fluid'],
    },
  };

  return mappings[index];
};

type SearchPageListViewProps = {
  addSessionStorage: (id: number) => void;
  debouncedOnChange: (filterObject: UrlServiceFilterObject) => void;
  filterAPI: FilterRequestAPI[];
  agentIdsFilter?: FilterSearchQuery;
  isLoading: boolean;
  isLoadingSection: boolean;
  isSoldSection: boolean;
  hiddenFilterAPI: FilterRequestAPI[];
  isMapView?: boolean;
  isMobile: boolean;
  listings: SearchPropertyCard[];
  paging: any;
  query: any;
  queryFilters?: FilterSearchQuery[];
  onChange: (filterObject: UrlServiceFilterObject) => void;
  reactDFPAdsReadyToLoad: boolean;
  SearchPagePagination: ComponentType<any>;
  section: SECTIONS;
  seoH1: string;
  setSelectedListing: React.Dispatch<React.SetStateAction<Listing | null>>;
  showcaseListings: [SearchPropertyCard];
  resetFilter: (filter: FilterRequestAPI) => void;
  toggleMapView: () => void;
  toggleSavedAd: ({
    adId,
    event,
    isSaved,
    userId,
  }: {
    adId: number;
    event: React.MouseEvent;
    isSaved?: boolean;
    userId: string;
  }) => void;
  savedAds: {
    [index: number]: boolean;
  };
  isRecentSection?: boolean;
};

const SearchPageListView = (props: SearchPageListViewProps) => {
  const {
    addSessionStorage,
    agentIdsFilter,
    debouncedOnChange,
    isLoading,
    isLoadingSection,
    filterAPI,
    hiddenFilterAPI,
    isMapView,
    isMobile,
    isSoldSection,
    listings,
    paging,
    query,
    queryFilters,
    onChange,
    reactDFPAdsReadyToLoad,
    resetFilter,
    savedAds,
    SearchPagePagination,
    section,
    showcaseListings,
    setSelectedListing,
    seoH1,
    toggleMapView,
    toggleSavedAd,
    isRecentSection,
  } = props;

  const { filteredListings } = useListingParams({
    isMapView,
    isMobile,
    isSoldSection,
    listings,
  });

  const { locationPath } = query;

  const showMapButton = !isRecentSection && !isSoldSection;

  const category = props.listings[0]?.listing?.category;
  /*
   * Basic ads should not be show on SRP, unless manually enabled by CS.
   * They will be displayed as Standard ads and should only appear
   * on the New Homes section.
   */
  const isBuyOrNewHomes = category && ['New Homes', 'Buy'].includes(category);
  const isResidentialForSale = section === SECTIONS.BUY;

  const locationFilterCurrentValue = filterAPI?.filter(
    (filter: any) => filter.name === 'location',
  )[0]?.currentValue;

  const showRadiusQuickLinks =
    locationFilterCurrentValue &&
    locationFilterCurrentValue?.location.length === 1 &&
    listings?.length > 0 &&
    paging.totalPages === paging.currentPage;

  const renderPanelAboveCards = !isMobile && isBuyOrNewHomes;
  const renderPanelBelowCards = (isBuyOrNewHomes && isMobile) || isSoldSection;

  const getMappedFilteredListings = useMemo(() => {
    const mappedFilteredListings = filteredListings.map(
      (srpCardsProps, index) => {
        const { id } = srpCardsProps;

        let SRPCard = null;

        try {
          SRPCard = renderCard(
            srpCardsProps,
            'baseCard',
            props.toggleSavedAd,
            props.savedAds,
          );
        } catch (error) {
          SRPCard = (
            <S.ErrorMessageContainer>
              Sorry, something went wrong when retrieving this ad
            </S.ErrorMessageContainer>
          );
        }

        const isPRSOrNewHome = !!(
          srpCardsProps.cardContentProps?.prsAd ||
          srpCardsProps.cardContentProps?.newHomeAd
        );

        const preparedPropertyUnitData = isPRSOrNewHome
          ? preparePropertyUnitData(
              srpCardsProps.featuredLevelFull,
              srpCardsProps.cardSubunitsProps,
            )
          : null;

        const SubunitCard = isPRSOrNewHome
          ? createCard('subunit', {
              cardSubunits: preparedPropertyUnitData.cardSubunits,
              remainingSubunits: preparedPropertyUnitData.remainingSubunits,
            })
          : null;
        return (
          <Fragment key={id}>
            <S.Result
              data-testid={`result-${id}`}
              onClick={() => addSessionStorage(id)}
            >
              <Link
                href={srpCardsProps.seoFriendlyPath}
                passHref
                legacyBehavior
              >
                <S.StyledA>
                  <SRPCard />
                </S.StyledA>
              </Link>
              {SubunitCard && <SubunitCard />}
            </S.Result>
            {renderPanelBelowCards && index === 1 && (
              <AdvantagePanel isMobile={isMobile} />
            )}
            {(index === 3 || index === 7 || index === 11) && (
              <DFPContainer
                AdSlot={AdSlot}
                adUnit={`daft/df_sp_search_${getUnitIdBasedOnPositionInResults(
                  index,
                )}`}
                reactDFPAdsReadyToLoad={reactDFPAdsReadyToLoad}
                sizeMapping={getSizesByIndex(index).sizeMapping}
                sizes={getSizesByIndex(index).sizes}
                slotId={`df_sp_search_${getUnitIdBasedOnPositionInResults(
                  index,
                )}`}
                styles={{ padding: '0 0 32px' }}
              />
            )}
          </Fragment>
        );
      },
    );
    return <>{mappedFilteredListings}</>;
  }, [filteredListings, reactDFPAdsReadyToLoad]);

  const toggleMapViewButtonRef = useRef<null | HTMLDivElement>(null);

  return (
    <>
      <DFPContainer
        AdSlot={AdSlot}
        adUnit="daft/df_sp_search_top"
        reactDFPAdsReadyToLoad={reactDFPAdsReadyToLoad}
        sizeMapping={[
          {
            viewport: VIEW_DESKTOP,
            sizes: [BILLBOARD, LEADERBOARD_XL, LEADERBOARD_LARGE, LEADERBOARD],
          },
          { viewport: VIEW_TABLET_PLUS, sizes: [LEADERBOARD] },
        ]}
        sizes={[BILLBOARD, LEADERBOARD, LEADERBOARD_XL, LEADERBOARD_LARGE]}
        slotId="df_sp_search_top"
      />
      <MainFlexWrapper isLoading={isLoading}>
        <S.MainColumn>
          <SearchPageListViewResultsHeader isMobileRow={!agentIdsFilter}>
            <S.StyledH1Wrapper isMobileRow={!agentIdsFilter}>
              <Text
                as="h1"
                data-testid="search-h1"
                fontWeight={TextFontWeight.SEMIBOLD}
                size={TextSize.M16}
              >
                {seoH1}
                {agentIdsFilter ? ' from' : ''}
              </Text>
            </S.StyledH1Wrapper>
            {agentIdsFilter?.values && (
              <S.FilterButtonsContainer>
                <FilterButtons
                  filters={queryFilters}
                  resetFilter={resetFilter}
                />
              </S.FilterButtonsContainer>
            )}
            <S.SearchResultsHeaderButtonContainer isMobileRow={!agentIdsFilter}>
              {showMapButton && (
                <div ref={toggleMapViewButtonRef}>
                  <Button
                    buttonSize={ButtonSizes.SMALL}
                    buttonType={ButtonTypes.SECONDARY}
                    data-testid="open-map-button"
                    data-tracking="open-mapsearch"
                    Icon={<Icon icon="MAPPIN" size={16} />}
                    onClick={toggleMapView}
                    type={SemanticButtonTypes.button}
                  >
                    Map View
                  </Button>
                </div>
              )}
              {!isRecentSection && (
                <S.SortFilterWrapper>
                  <SortFilter
                    currentValue={query['sort']}
                    id="srp-sort-listings"
                    onChange={debouncedOnChange}
                    section={section}
                    showLabel={false}
                  />
                </S.SortFilterWrapper>
              )}
            </S.SearchResultsHeaderButtonContainer>
          </SearchPageListViewResultsHeader>
          {isSoldSection && (
            <>
              <S.SoldDataNotificationWrapper>
                <Notification
                  notificationStatus={NotificationStatus.INFORMATIONAL}
                  notificationVariants={NotificationVariants.Inline}
                  icon={
                    <Icon
                      icon="INFORMATIONCIRCLE"
                      size={20}
                      color={'#4170C4'}
                    />
                  }
                  notificationSizes={NotificationFontSizes.MEDIUM}
                  title={'How we source details for the Sold Section'}
                  titleColor={'#333333'}
                  text={
                    'Daft shows all properties listed in the PSRA Property Price Register. Location details, sold dates and prices come from there, with some results having additional content from historic Daft ads.'
                  }
                  closeIcon={false}
                />
              </S.SoldDataNotificationWrapper>
              <Space space={`${isMobile ? 'M24' : 'L40'}`} />
            </>
          )}

          {renderPanelAboveCards && <AdvantagePanel isMobile={isMobile} />}
          <S.SearchResults data-testid="results">
            {showcaseListings && showcaseListings.length > 0 && (
              <SRPResultCards
                addSessionStorage={addSessionStorage}
                cardTestID="result-showcase"
                isShowcase={true}
                listings={showcaseListings}
                savedAds={savedAds}
                setSelectedListing={setSelectedListing}
                toggleSavedAd={toggleSavedAd}
              />
            )}
            {!listings || listings.length === 0 ? (
              <ZeroResults
                filterAPI={filterAPI
                  .concat(hiddenFilterAPI)
                  .concat(queryFilters)}
                resetFilter={resetFilter}
                buttonType="SECONDARY"
              />
            ) : null}
            {getMappedFilteredListings}
            {showRadiusQuickLinks && !isSoldSection ? (
              <QuickLinksRadiusFilter
                onChange={onChange}
                locationFilterCurrentValue={locationFilterCurrentValue}
              />
            ) : null}
          </S.SearchResults>
          {!isSoldSection && isResidentialForSale && (
            <>
              <Space space={{ xl: 'M32', sm: 'M24' }} />
              <Notification
                notificationStatus={NotificationStatus.INFORMATIONAL}
                notificationVariants={NotificationVariants.Inline}
                icon={<Icon icon="LIGHTBULB" size={20} color={'#4170C4'} />}
                notificationSizes={NotificationFontSizes.MEDIUM}
                title={'Explore Sold Properties'}
                titleColor={'#333333'}
                text={'Stay informed with recent sales and market trends.'}
                closeIcon={false}
                cta={
                  <StyledLink
                    href={`/sold-properties/${locationPath}`}
                    dataTracking="Sold_entry_BuySRP"
                  >
                    Explore Daft Sold Section
                  </StyledLink>
                }
              />
            </>
          )}
          <ErrorBoundary
            renderFallback={() => (
              <S.ErrorMessageContainer>
                Sorry, something went wrong with the Search Page Pagination
              </S.ErrorMessageContainer>
            )}
          >
            <SearchPagePagination
              disabled={isLoading || isLoadingSection}
              onUpdate={onChange}
              paging={paging}
            />
          </ErrorBoundary>
        </S.MainColumn>
        <S.SideColumnWrapper>
          <SideColumn
            reactDFPAdsReadyToLoad={props.reactDFPAdsReadyToLoad}
            section={section}
          />
        </S.SideColumnWrapper>
        {!isSoldSection && (
          <SearchPageListViewStickyButton
            toggleMapView={toggleMapView}
            toggleMapViewButtonRef={toggleMapViewButtonRef}
          />
        )}
      </MainFlexWrapper>
    </>
  );
};

export default SearchPageListView;
